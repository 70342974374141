<template>
    <div>
        <div class="mb-1">
            <router-view></router-view>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
    created() {
        this.$store.dispatch('updatePageTitle', {
            title: this.$t('Consumidores'),
            subtitle: this.$t('Adicione y gerencie los consumidores finales'),
        });
    },

    methods : {
        toggleRoute() {
            if( this.route === '/app/users/list' ) this.$router.push({ path : '/app/users/new' });
            else this.$router.push({ path : '/app/users/list' });
        },
    },
    
    computed : {
        route() {
            return this.$route.path;
        }
    }
})
</script>
